<template>
  <v-container>
    <!-- Add success dialog -->
    <v-dialog v-model="showSuccessDialog" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('modal.reservationSuccessTitle') }}</v-card-title>
        <v-card-text>{{ $t('modal.reservationSuccessMessage') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" class="text-uppercase" @click="closeSuccessModal">{{ $t('buttons.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Add error dialog -->
    <v-dialog v-model="showErrorDialog" max-width="400px">
      <v-card>
        <v-card-title>{{ $t('modal.reservationErrorTitle') }}</v-card-title>
        <v-card-text>{{ $t('modal.reservationErrorMessage') }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" class="text-uppercase" @click="openWebsite">{{ $t('buttons.visitWebsite') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="organisation-wrapper">
      <v-col cols="12" lg="5" xl="4">
        <v-row>
          <v-col cols="12">
            <v-img
              alt="Logo"
              class="organisation-logo"
              contain
              :src="organisationData?.logo_path"
              transition="scale-transition" />
          </v-col>
          <v-col cols="12" sm="6" lg="12">
            <strong>{{ $t('organisationsPage.name') }}:</strong>
            <div>{{ organisationData?.name || '/' }}</div>
          </v-col>
          <v-col cols="12" sm="6" lg="12">
            <strong>{{ $t('organisationsPage.email') }}:</strong>
            <div>{{ organisationData?.email || '/' }}</div>
          </v-col>
          <v-col cols="12" sm="6" lg="12">
            <strong>{{ $t('organisationsPage.phoneNumber') }}:</strong>
            <div>{{ organisationData?.phone_number || '/' }}</div>
          </v-col>
          <v-col cols="12" sm="6" lg="12">
            <strong>{{ $t('organisationsPage.address') }}:</strong>
            <div>{{ organisationData?.address || '/' }}</div>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" lg="7" xl="8">
        <!-- Stepper -->
        <validation-observer ref="drivingForm" v-slot="{ handleSubmit, failed }">
          <v-form lazy-validation @submit.prevent="handleSubmit(handleNextStep)">
            <v-stepper v-model="activeStep">
              <!-- Step 1 -->
              <v-stepper-step :complete="activeStep > 1" step="1"> {{ $t('driving.steps.mainInfo') }} </v-stepper-step>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-title>{{ $t('driving.steps.mainInfo') }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <validation-provider rules="required" v-slot="{ errors }" name="driving_type">
                            <v-autocomplete
                              v-model="editedItem.driving_type_id"
                              :items="drivingTypes"
                              clearable
                              item-text="name"
                              item-value="id"
                              :label="$t('driving.drivingType')"
                              :no-data-text="$t('select.noDataAvailable')"
                              @focus="$event.target.click()"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-autocomplete>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-menu
                            ref="pickupTime"
                            v-model="pickupTime"
                            :close-on-content-click="false"
                            :return-value.sync="editedItem.pickup_time"
                            min-width="auto"
                            offset-y
                            transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                              <validation-provider rules="required" v-slot="{ errors }" name="pickup_time">
                                <v-text-field
                                  v-model="editedItem.pickup_time"
                                  clearable
                                  :label="$t('driving.pickupTime')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="formDisabled"
                                  :error-messages="errors"></v-text-field>
                              </validation-provider>
                            </template>
                            <DatePicker
                              v-model="editedItem.pickup_time"
                              :minute-increment="1"
                              :model-config="modelConfig"
                              class="no-border"
                              is24hr
                              :min-date="new Date()"
                              mode="dateTime">
                            </DatePicker>
                            <div class="date-picker-buttons">
                              <v-btn class="primary cancel mr-2" elevation="2" text @click="pickupTime = false">
                                {{ $t('buttons.cancel') }}
                              </v-btn>
                              <v-btn class="primary mr-2" elevation="2" text @click="updatePickupTime">
                                {{ $t('buttons.confirm') }}
                              </v-btn>
                            </div>
                          </v-menu>
                        </v-col>

                        <v-col cols="12" sm="6">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedItem.from_location"
                            selectFirstOnEnter
                            @place_changed="selectFromAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required|max:200" v-slot="{ errors }" name="location_from">
                                <v-text-field
                                  ref="input"
                                  clearable
                                  v-model="editedItem.from_location_name"
                                  :label="$t('driving.locationFrom')"
                                  @focusout="selectFromAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :disabled="formDisabled"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  counter="200">
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="editedItem.driving_type_id === 1">
                          <gmap-autocomplete
                            :options="googleMapsOptions"
                            :setFieldsTo="GoogleApiFields"
                            :value="editedItem.to_location"
                            selectFirstOnEnter
                            slot-ref-name="input2"
                            @place_changed="selectToAddress">
                            <template v-slot:default="slotProps">
                              <validation-provider rules="required|max:200" v-slot="{ errors }" name="location_to">
                                <v-text-field
                                  ref="input2"
                                  clearable
                                  v-model="editedItem.to_location_name"
                                  :label="$t('driving.locationTo')"
                                  @focusout="selectToAddressFocusout"
                                  v-on:attrs="slotProps.attrs"
                                  v-on:listeners="slotProps.listeners"
                                  :disabled="formDisabled"
                                  :error-messages="errors"
                                  @keydown.enter.prevent
                                  counter="200">
                                </v-text-field>
                              </validation-provider>
                            </template>
                          </gmap-autocomplete>
                        </v-col>
                        <v-col cols="12" lg="6" md="6" sm="12">
                          <v-autocomplete
                            v-model="editedItem.vehicle_class_id"
                            :items="vehicleClasses"
                            clearable
                            item-text="name"
                            item-value="id"
                            :label="$t('driving.vehicleClass')"
                            :no-data-text="$t('select.noDataAvailable')"
                            :disabled="formDisabled"
                            @focus="$event.target.click()"></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="6" v-if="editedItem.driving_type_id == 2">
                          <validation-provider
                            rules="numeric|max:2|min_value:0|max_value:24"
                            v-slot="{ errors }"
                            name="waiting_time">
                            <v-text-field
                              v-model="editedItem.num_of_waiting_hours"
                              clearable
                              :label="$t('driving.waitingTime')"
                              type="text"
                              counter="2"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider rules="required|max:200" v-slot="{ errors }" name="full_name">
                            <v-text-field
                              v-model="editedItem.full_name"
                              name="full_name"
                              clearable
                              counter="200"
                              :label="$t('driving.fullName')"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            :rules="{
                              required: true,
                              max: 200,
                              regex: /^\+?[0-9\s\-()]+$/,
                            }"
                            v-slot="{ errors }"
                            name="phone_number">
                            <v-text-field
                              v-model="editedItem.phone_number"
                              name="phone"
                              clearable
                              counter="200"
                              :label="$t('driving.phoneNumber')"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            rules="required|email|max:200"
                            v-slot="{ errors }"
                            name="email"
                            ref="emailRef">
                            <v-text-field
                              v-model="editedItem.email"
                              name="email"
                              clearable
                              counter="200"
                              :label="$t('driving.email')"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <button-submit :failed="failed || formDisabled" buttonText="buttons.next"></button-submit>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <!-- Step 2 -->
              <v-stepper-step :complete="activeStep > 2" step="2">
                {{ $t('driving.steps.additionalInfo') }}
              </v-stepper-step>
              <v-stepper-content step="2">
                <v-card>
                  <v-card-title>{{ $t('driving.steps.additionalInfo') }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            :rules="{ max: 10, regex: /^[A-Z,a-z]{1,3}\d{1,5}$/ }"
                            v-slot="{ errors }"
                            name="flight_number"
                            class="d-flex align-center justify-space-between">
                            <v-text-field
                              v-model="editedItem.flight_number"
                              clearable
                              :label="$t('driving.flightNumber')"
                              type="text"
                              counter="10"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider rules="max:200" v-slot="{ errors }" name="waiting_board_text">
                            <v-text-field
                              v-model="editedItem.waiting_board_text"
                              clearable
                              :label="$t('driving.waitingBoardText')"
                              type="text"
                              counter="200"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            rules="numeric|max:10"
                            v-slot="{ errors }"
                            name="suitcase_number"
                            class="d-flex align-center justify-space-between">
                            <v-text-field
                              v-model="editedItem.suitcase_number"
                              clearable
                              :label="$t('driving.suitcasesNumber')"
                              type="text"
                              counter="10"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            rules="numeric|max:10"
                            v-slot="{ errors }"
                            name="passenger_number"
                            class="d-flex align-center justify-space-between">
                            <v-text-field
                              v-model="editedItem.passenger_number"
                              clearable
                              :label="$t('driving.passengersNumber')"
                              type="text"
                              counter="10"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            rules="numeric|max:10"
                            v-slot="{ errors }"
                            name="baby_seat_number"
                            class="d-flex align-center justify-space-between">
                            <v-text-field
                              v-model="editedItem.baby_seat_number"
                              clearable
                              :label="$t('driving.babySeatsNumber')"
                              type="text"
                              counter="10"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            rules="numeric|max:10"
                            v-slot="{ errors }"
                            name="stop_number"
                            class="d-flex align-center justify-space-between">
                            <v-text-field
                              v-model="editedItem.stop_number"
                              clearable
                              :label="$t('driving.stopsNumber')"
                              type="text"
                              counter="10"
                              :disabled="formDisabled"
                              :error-messages="errors"></v-text-field>
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                            <v-textarea
                              v-model="editedItem.note"
                              clearable
                              counter="1000"
                              :label="$t('driving.note')"
                              rows="2"
                              auto-grow
                              :disabled="formDisabled"
                              :error-messages="errors"></v-textarea>
                          </validation-provider>
                        </v-col>

                        <v-col cols="12" md="12" sm="12">
                          <add-files
                            :editedItem="editedItem"
                            :attachments="editedItem.attachments"
                            :filesToSend="filesToSend"
                            deleteRoute="drivings/deleteDrivingAttachment"
                            @addNewFile="addNewFile"
                            @deleteNewFile="deleteNewFile"></add-files>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary cancel" @click="prevStep">{{ $t('buttons.prev') }}</v-btn>
                    <v-spacer></v-spacer>
                    <button-submit :failed="failed || formDisabled" buttonText="buttons.next"></button-submit>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>

              <!-- Step 3 -->
              <v-stepper-step :complete="activeStep > 3" step="3"> {{ $t('driving.steps.review') }} </v-stepper-step>
              <v-stepper-content step="3">
                <v-card>
                  <v-card-title>{{ $t('driving.steps.review') }}</v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <!-- Driving Type -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.drivingType') }}:</strong>
                          </div>
                          <div>{{ getDrivingTypeLabel(editedItem.driving_type_id) || '/' }}</div>
                        </v-col>
                        <!-- Pickup Time -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.pickupTime') }}:</strong>
                          </div>
                          <div>{{ editedItem.pickup_time || '/' }}</div>
                        </v-col>

                        <!-- From Location -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.locationFrom') }}:</strong>
                          </div>
                          <div>{{ editedItem.from_location_name || '/' }}</div>
                        </v-col>
                        <!-- To Location (conditionally displayed) -->
                        <v-col cols="12" sm="6" lg="6" v-if="editedItem.driving_type_id === 1">
                          <div>
                            <strong>{{ $t('driving.locationTo') }}:</strong>
                          </div>
                          <div>{{ editedItem.to_location_name || '/' }}</div>
                        </v-col>
                        <!-- Waiting Time -->
                        <v-col cols="12" sm="6" lg="6" v-if="editedItem.driving_type_id === 2">
                          <div>
                            <strong>{{ $t('driving.waitingTime') }}:</strong>
                          </div>
                          <div>{{ editedItem.num_of_waiting_hours || '/' }}</div>
                        </v-col>

                        <!-- Full Name -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.fullName') }}:</strong>
                          </div>
                          <div>{{ editedItem.full_name || '/' }}</div>
                        </v-col>
                        <!-- Vehicle class -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.vehicleClass') }}:</strong>
                          </div>
                          <div>{{ getVehicleClassName(editedItem.vehicle_class_id) || '/' }}</div>
                        </v-col>

                        <!-- Phone Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.phoneNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.phone_number || '/' }}</div>
                        </v-col>

                        <!-- Email -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.email') }}:</strong>
                          </div>
                          <div>{{ editedItem.email || '/' }}</div>
                        </v-col>

                        <!-- Flight Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.flightNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.flight_number || '/' }}</div>
                        </v-col>

                        <!-- Welcome Sign -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.waitingBoardText') }}:</strong>
                          </div>
                          <div>{{ editedItem.waiting_board_text || '/' }}</div>
                        </v-col>

                        <!-- Suitcase Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.suitcasesNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.suitcase_number || '/' }}</div>
                        </v-col>

                        <!-- Passenger Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.passengersNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.passenger_number || '/' }}</div>
                        </v-col>

                        <!-- Baby Seat Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.babySeatsNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.baby_seat_number || '/' }}</div>
                        </v-col>

                        <!-- Stop Number -->
                        <v-col cols="12" sm="6" lg="6">
                          <div>
                            <strong>{{ $t('driving.stopsNumber') }}:</strong>
                          </div>
                          <div>{{ editedItem.stop_number || '/' }}</div>
                        </v-col>

                        <!-- Additional Note -->
                        <v-col cols="12" sm="12" lg="12">
                          <div>
                            <strong>{{ $t('driving.noteDispatcher') }}:</strong>
                          </div>
                          <div>{{ editedItem.note || '/' }}</div>
                        </v-col>

                        <v-col cols="12">
                          <template v-for="(file, index) in filesToSend">
                            <div :key="index">{{ file.name }}</div>
                          </template>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary cancel" @click="prevStep">{{ $t('buttons.prev') }}</v-btn>
                    <v-spacer></v-spacer>
                    <button-submit
                      :loading="loading"
                      :failed="failed || formDisabled"
                      buttonText="buttons.send"></button-submit>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';
import store from '@/store';
import { DatePicker } from 'v-calendar/src/components';
import AddFiles from '@/components/AddFiles.vue';
import { formatDateTimeToDDMMHHmm } from '@/utils/formatDate';
import { defaultReservationDrivingItem } from '@/mixins/default-items';

export default {
  name: 'PublicReservationForm',
  components: {
    ButtonSubmit,
    DatePicker,
    AddFiles,
  },
  data() {
    return {
      activeStep: 1, // Keeps track of the current active step
      steps: [{ title: 'Step 1' }, { title: 'Step 2' }, { title: 'Step 3' }],
      editedItem: {},
      drivingTypes: [
        { id: 1, name: i18n.t('drivingOrderType.transfer') },
        { id: 2, name: i18n.t('drivingOrderType.dailyRent') },
      ],
      pickupTime: null,
      googleMapsOptions: {
        bounds: {
          north: 45.830329,
          south: 41.51274,
          east: 20.834725,
          west: 16.332208,
        },
        // componentRestrictions: {country: ["me", "hr", "rs", "ba", "al"]},
        strictBounds: false,
      },
      GoogleApiFields: ['name', 'formatted_address', 'geometry', 'place_id', 'plus_code', 'url'],
      modelConfig: {
        type: 'string',
        mask:
          store.getters['auth/user']?.organisation?.organisation_settings?.date_format == 'mm-dd'
            ? 'MM-DD-YYYY HH:mm'
            : 'DD-MM-YYYY HH:mm',
      },
      filesToSend: [],
      loading: false,
      organisationData: {},
      vehicleClasses: [],
      showSuccessDialog: false,
      showErrorDialog: false,
      formDisabled: false,
    };
  },
  created() {
    if (this.$route.query.param) {
      this.editedItem = { ...defaultReservationDrivingItem, public_booking_id: this.$route.query.param };
      this.getPublicBookingData();
    } else {
      window.location.href = 'https://limoexpress.me/';
    }

    this.handleReceivedParameters(this.$route.query);

    this.handleLanguage();
  },
  methods: {
    handleNextStep() {
      switch (this.activeStep) {
        case 1:
          this.nextStep();
          break;
        case 2:
          this.nextStep();
          break;
        case 3:
          this.preSubmitForm();
          break;
        default:
          break;
      }
    },
    nextStep() {
      if (this.activeStep < 3) {
        this.activeStep++;
      }
    },
    prevStep() {
      if (this.activeStep > 1) {
        this.activeStep--;
      }
    },
    async getPublicBookingData() {
      await this.$store
        .dispatch('drivings/getPublicBookingData', {
          id: this.editedItem.public_booking_id,
        })
        .then((res) => {
          this.organisationData = res.data.organisation;
          this.vehicleClasses = res.data.vehicleClasses;
        })
        .catch(() => {
          this.showErrorDialog = true;
          this.formDisabled = true;
        });
    },
    createFormData(token) {
      let formData = new FormData();

      formData.append('public_booking_id', this.editedItem.public_booking_id ? this.editedItem.public_booking_id : '');
      formData.append('driving_type_id', this.editedItem.driving_type_id ? this.editedItem.driving_type_id : '');
      formData.append(
        'pickup_time',
        this.editedItem.pickup_time ? formatDateTimeToDDMMHHmm(this.editedItem.pickup_time) : ''
      );
      formData.append('from_location', JSON.stringify(this.editedItem.from_location));
      formData.append(
        'from_location_name',
        this.editedItem.from_location_name ? this.editedItem.from_location_name : ''
      );
      this.editedItem.driving_type_id === 1 &&
        formData.append('to_location', JSON.stringify(this.editedItem.to_location));
      formData.append('to_location_name', this.editedItem.to_location_name ? this.editedItem.to_location_name : '');
      formData.append('vehicle_class_id', this.editedItem.vehicle_class_id ? this.editedItem.vehicle_class_id : '');
      this.editedItem.driving_type_id === 2 &&
        formData.append(
          'num_of_waiting_hours',
          this.editedItem.num_of_waiting_hours ? this.editedItem.num_of_waiting_hours : ''
        );
      formData.append('full_name', this.editedItem.full_name ? this.editedItem.full_name : '');
      formData.append('phone_number', this.editedItem.phone_number ? this.editedItem.phone_number : '');
      formData.append('email', this.editedItem.email ? this.editedItem.email : '');
      formData.append('flight_number', this.editedItem.flight_number ? this.editedItem.flight_number : '');
      formData.append(
        'waiting_board_text',
        this.editedItem.waiting_board_text ? this.editedItem.waiting_board_text : ''
      );
      formData.append('suitcase_number', this.editedItem.suitcase_number ? this.editedItem.suitcase_number : '');
      formData.append('passenger_number', this.editedItem.passenger_number ? this.editedItem.passenger_number : '');
      formData.append('baby_seat_number', this.editedItem.baby_seat_number ? this.editedItem.baby_seat_number : '');
      formData.append('stop_number', this.editedItem.stop_number ? this.editedItem.stop_number : '');
      formData.append('note', this.editedItem.note ? this.editedItem.note : '');
      this.filesToSend.map((item) => {
        formData.append('attachments[]', item);
      });
      const passengers = this.getPassengers();
      formData.append('passengers', JSON.stringify(passengers));
      formData.append('token', token);

      return formData;
    },
    async preSubmitForm() {
      try {
        // Call reCAPTCHA verification
        const token = await this.$recaptcha('public_reservation_form');
        // You can define any action name here

        // Submit your form or make an API call here with the token
        await this.submitForm(token);
      } catch {
        this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    submitForm(token) {
      this.loading = true;
      let formData = this.createFormData(token);

      this.$store
        .dispatch('drivings/sendPublicReservation', formData)
        .then(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.sentSuccessfully'), color: 'green' });

          // Show success modal, reset form, and return to the first slide
          this.showSuccessDialog = true;
          this.resetForm(); // Call reset form function
        })
        .catch((error) => {
          if (error.response?.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      // Reset form fields and stepper
      this.$refs.drivingForm.reset();
      this.activeStep = 1;
      this.editedItem = { ...defaultReservationDrivingItem, public_booking_id: this.$route.query.param };
    },
    getPassengers() {
      const fullName = this.editedItem.full_name.trim(); // Ensure there are no leading or trailing spaces

      // Split the full name into an array of words
      const nameParts = fullName.split(' ');

      // Assign the first part as the first name
      const firstName = nameParts[0];

      // Join the remaining parts as the last name (in case there are middle names)
      const lastName = nameParts.slice(1).join(' ');

      return [
        {
          first_name: firstName,
          last_name: lastName,
          phone: this.editedItem.phone_number,
          email: this.editedItem.email,
        },
      ];
    },
    closeSuccessModal() {
      this.showSuccessDialog = false;
    },
    updatePickupTime() {
      this.$refs.pickupTime.save(this.editedItem.pickup_time);
    },
    selectFromAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.from_location?.name &&
        value !== this.editedItem.from_location?.name + ` (${this.editedItem.from_location?.formatted_address})`
      ) {
        this.editedItem.from_location = { name: value };
        this.editedItem.from_location_name = value;
      }
    },
    selectToAddressFocusout(input) {
      let value = input.target.value;
      if (
        value !== this.editedItem.to_location?.name &&
        value !== this.editedItem.to_location?.name + ` (${this.editedItem.to_location?.formatted_address})`
      ) {
        this.editedItem.to_location = { name: value };
        this.editedItem.to_location_name = value;
      }
    },
    selectFromAddress(input) {
      this.editedItem.from_location = input;
      this.editedItem.from_location_name = input.name + ` (${input.formatted_address})`;
    },
    selectToAddress(input2) {
      this.editedItem.to_location = input2;
      this.editedItem.to_location_name = input2.name + ` (${input2.formatted_address})`;
    },
    addNewFile(item) {
      this.filesToSend = [...this.filesToSend, item];
    },
    deleteOldFile(index) {
      this.editedItem.attachments.splice(index, 1);
    },
    deleteNewFile(index) {
      this.filesToSend.splice(index, 1);
    },
    getDrivingTypeLabel(typeId) {
      const drivingType = this.drivingTypes.find((type) => type.id === typeId);
      return drivingType ? drivingType.name : '';
    },
    openWebsite() {
      window.open('https://limoexpress.me');
    },
    handleReceivedParameters(params) {
      // preselect driving type if we receive from and/or to location
      if (params.from && params.to) {
        this.editedItem.driving_type_id = 1;
      } else if (params.from) {
        this.editedItem.driving_type_id = 2;
      }
      params.from ? (this.editedItem.from_location = this.makeLocationObject(params.from)) : '';
      params.from ? (this.editedItem.from_location_name = params.from) : '';
      params.to ? (this.editedItem.to_location = this.makeLocationObject(params.to)) : '';
      params.to ? (this.editedItem.to_location_name = params.to) : '';
      params.pickup_time && this.isValidDateFormat(params.pickup_time)
        ? (this.editedItem.pickup_time = params.pickup_time)
        : '';
      params?.passenger_first_name && params?.passenger_last_name
        ? (this.editedItem.full_name = params?.passenger_first_name + ' ' + params?.passenger_last_name)
        : '';
      params.passenger_number ? (this.editedItem.phone_number = params.passenger_number) : '';
      params.passenger_email ? (this.editedItem.email = params.passenger_email) : '';
      params.note ? (this.editedItem.note = params.note) : '';
      params.flight_number ? (this.editedItem.flight_number = params.flight_number) : '';
      params.waiting_board_text ? (this.editedItem.waiting_board_text = params.waiting_board_text) : '';
      params.num_of_suit_cases ? (this.editedItem.suitcase_number = params.num_of_suit_cases) : '';
      params.num_of_passengers ? (this.editedItem.passenger_number = params.num_of_passengers) : '';
      params.num_of_baby_seats ? (this.editedItem.baby_seat_number = params.num_of_baby_seats) : '';
      params.num_of_stops ? (this.editedItem.stop_number = params.num_of_stops) : '';
    },
    handleLanguage() {
      const currentLanguage = localStorage.getItem('currentLanguage');
      const receivedLang = this.$route.query.language;

      if (receivedLang) {
        this.$store.dispatch('language/setLanguage', receivedLang);
        localStorage.setItem('currentLanguage', receivedLang);
      } else if (currentLanguage) {
        this.$store.dispatch('language/setLanguage', currentLanguage);
        localStorage.setItem('currentLanguage', currentLanguage);
      } else {
        this.$store.dispatch('language/setLanguage', 'en');
        localStorage.setItem('currentLanguage', 'en');
      }
    },
    makeLocationObject(location) {
      return {
        formatted_address: location,
        geometry: {
          location: { lat: null, lng: null },
          viewport: {
            south: null,
            west: null,
            north: null,
            east: null,
          },
        },
        name: location,
        place_id: null,
        url: null,
        html_attributions: [],
      };
    },
    isValidDateFormat(dateString) {
      // Define regex for the format dd-mm-yyyy hh:mm
      const regex = /^(0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-\d{4} ([01][0-9]|2[0-3]):[0-5][0-9]$/;

      // Test if the dateString matches the regex
      return regex.test(dateString);
    },
    getVehicleClassName(vehicleClassId) {
      return this.vehicleClasses.find((item) => (item.id === vehicleClassId))?.name;
    },
  },
};
</script>

<style scoped>
/* Optional: Add any additional styling here */
.organisation-wrapper {
  max-width: 1400px;
  margin: auto;
}

.organisation-logo {
  height: auto;
  width: 100%;
  max-width: 150px;
}
</style>
